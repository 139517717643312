<template>
<div>
  <div class="contents_box" v-show="isStep1">
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">ご利用プラン</p></th>
        <td>
          <p v-show="!!currentPlan.mst_name" class="plan_name_wrap">
            <span class="plan_name">{{currentPlan.mst_name}}</span>
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">個数</p></th>
        <td>
          <p>
            <select v-model="count" @change="setPrice()" class="count">
              <option v-for="elem in counts" v-bind:value="elem">
                {{elem}}個
              </option>
            </select>
          </p>
        </td>
      </tr>
      <tr>
        <th class="form_line_bt"><p class="item2">集配日時</p></th>
        <td class="form_line_bt">
          <p class="pc_br_none tab_br_none sp_br_yes">集荷：<br>
            <select v-model="pickupMonth" @change="chageDate(1,1)" class="w55">
              <option v-for="elem in months" v-bind:value="elem">
                {{elem}}
              </option>
            </select>月
            <select v-model="pickupDate" @change="chageDate(1,2)" class="w55">
              <option v-for="elem in pickupDates" v-bind:value="elem">
                {{elem}}
              </option>
            </select>日
            <select v-model="pickupHour" @change="chageDate(1,3)" class="w55">
              <option v-for="elem in pickupConvHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            ：
            <select v-model="pickupMinute" @change="chageDate(1,4)" class="w55">
              <option v-for="elem in pickupMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            <!-- <span>　〜　{{toPickupTime | dtFormat('HH　:　MM')}}</span> -->
          </p>

          <p class="mg10 pc_br_none tab_br_none sp_br_yes">配達：<br>
            <span>
              <select v-model="deliveryMonth" @change="chageDate(2,1)" class="w55">
                <option v-for="elem in months" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>月
              <select v-model="deliveryDate" @change="chageDate(2,2)" class="w55">
                <option v-for="elem in deliveryDates" v-bind:value="elem">
                  {{elem}}
                </option>
              </select>日
              <select v-model="deliveryHour" @change="chageDate(2,3)" class="w55">
                <option v-for="elem in deliveryConvHours" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
              ：
              <select v-model="deliveryMinute" @change="chageDate(2,4)" class="w55">
                <option v-for="elem in deliveryMinutes" v-bind:value="elem.val">
                  {{elem.disp}}
                </option>
              </select>
            </span>
          </p>
          <p class="font_orange mg10 font_14">
            ※18時以降が安くてお勧め<br>
            ※集荷は90分以内、配達は前後2時間で集配
          </p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">料金</p></th>
        <td><p>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p></td>
      </tr>
      <tr>
        <th>
          <p class="item2">宅配ボックス/パスワード</p>
        </th>
        <td>
          <p>
            <input type="text" v-model="deliveryBoxPw"
              class="form_basic">
          </p>
          <p class="font_orange mg10 font_14">※宅配ボックスによる集荷を希望する方のみ記載してください</p>
        </td>
      </tr>

      <tr>
        <th><p class="item2">備考</p></th>
        <td>
          <p>
            <textarea type="text" name="message" id="message"
              v-model="bikou" maxlength="500"
              size="20" class="form_basic" /></textarea>
          </p>
        </td>
      </tr>

      <tr>
        <th class="form_line_l"></th>
        <td class="form_line_r">
          <wash-request-onetime-notice-messages>
          </wash-request-onetime-notice-messages>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="showErrMsg1">
        日付が間違っています
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        配達時間は集荷時間から休業日を除く24時間後以降を指定してください
      </span>
      <span class="font_orange" v-show="showErrMsg3">
        配達時間は現在より30日以内を指定してください
      </span>
      <span class="font_orange" v-show="showErrMsg4">
        提供エリア外のためご利用いただけません
      </span>
      <span class="font_orange" v-show="showErrMsg5">
        選択された集荷日時は提要不可です
      </span>
    </div>
    <p class="submit_bx1">
      <button @click="nextStep()" class="submit1 bg_green step1do"
          :disabled="role == 200">
        確認画面
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isStep2">
    <p class="mypage_notes mg30 mg_bt30 font_orange">
      下記の日時でよろしければ送信してください。
    </p>
    <table class="form_tab form_line_top">
      <tr>
        <th><p class="item2">個数</p></th>
        <td><p>{{count}}個</p></td>
      </tr>
      <tr>
        <th><p class="item2">集荷日時</p></th>
        <td><p>{{toPickupTime | dtFormat('m月d日（a）HH：MM')}}</p></td>
      </tr>
      <tr>
        <th class="form_line_l"><p class="item2">配達日時</p></th>
        <td class="form_line_r">
          <p>{{deliveryDt | dtFormat('m月d日（a）HH：MM')}}</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">料金</p></th>
        <td>
          <p>{{price | currency('',0)}}円({{unitPrice | currency('',0)}}円/個)</p>
        </td>
      </tr>
      <tr>
        <th><p class="item2">宅配ボックス/パスワード</p></th>
        <td><p>{{deliveryBoxPw}}</p></td>
      </tr>
      <tr>
        <th><p class="item2">備考</p></th>
        <td><p v-html="$options.filters.lineBreakToBR(bikou)"></p></td>
      </tr>
    </table>
    <div class="err-msg-area">
      <p><span class="font_orange" v-show="showErrMsg1">
        日付が間違っています
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg2">
        配達時間は集荷時間から休業日を除く24時間後以降を指定してください
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg3">
        配達時間は現在より30日以内を指定してください
      </span></p>
      <p><span class="font_orange" v-show="showErrMsg4">
        提供エリア外のためご利用いただけません
      </span></p>
      <span class="font_orange" v-show="showErrMsg5">
        選択された集荷日時は提要不可です
      </span>
      <p><span class="font_orange" v-show="isDupRequest">
        その集配日時で既に依頼済です
      </span></p>
      <p><span class="font_orange" v-show="isStatusDisabled">
        有効なアカウント情報が確認できませんでした
      </span></p>
      <p><span class="font_orange" v-show="isStatusWithdrawn">
        このアカウントは退会済みです
      </span></p>
      <p><span class="font_orange" v-show="isStatusStoppedOther">
        一時停止中のアカウントです
      </span></p>
    </div>
    <p class="submit_bx_l">
      <button @click="prevStep()" class="submit1 bg_gray">
        戻る
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="addWashRequest()" class="submit1 bg_green">
        カード情報入力へ
      </button>
    </p>
  </div>
  <form id="payment-form" method="post"
        :action="paymentInfo.url" accept-charset="Shift_JIS">
      <input type="hidden" v-for="elem in paymentInfo.formParams"
        :name="elem.name" :value="elem.value">
      <input type="hidden" name="RETURL" :value="paymentInfo.retUrl">
  </form>
</div>
</template>

<script>
import Vue from 'vue'
import userApi from '@/api/user'
import areaInfoApi from '@/api/areaInfo'
import dateMixin from '@/mixin/dateMixin'
import { mapState } from 'vuex'

export default {
  name: 'wash-request',
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      currentPlan: {},
      months: window.VueMonthOptions,
      dates: window.VueDateOptions,
      hours: window.VueHourOptions,
      minutes: this.setMinuteOption(),
      minutes30: this.setMinuteOption([0,15,30]),
      minutes0: this.setMinuteOption([0]),
      pickupDates: [],
      deliveryDates: [],
      pickupConvHours: window.VueHourOptions,
      deliveryConvHours: window.VueHourOptions,
      lastPickupConvHours: null,
      lastDeliveryConvHours: null,
      pickupMinutes: this.setMinuteOption(),
      deliveryMinutes: this.setMinuteOption(),
      areaInfo: [],

      counts: [
        1,2,3,4,5
      ],
      pickupYear: 9999,
      pickupMonth: 1,
      pickupDate: 1,
      pickupHour: 13,
      pickupMinute: 0,
      pickupDt: null,
      deliveryYear: 9999,
      deliveryMonth: 1,
      deliveryDate: 1,
      deliveryHour: 16,
      deliveryMinute: 0,
      deliveryDt: null,
      count: 1,
      deliveryBoxPw: '',
      price: '',
      unitPrice: '',
      // Business Rule No.20 備考欄は最大で全角500文字まで入力可能
      bikou: '',
      zipcode: '',
      businessHolidays: [],
      onetimePayments: [],

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },

      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      showErrMsg4: false,
      showErrMsg5: false,

      isWaitingResponse: false,
      isDupRequest: false,
      isStatusDisabled: false,
      isStatusWithdrawn: false,
      isStatusStoppedOther: false,
    }
  },
  computed: {
    ...mapState('user', {
      // Business Rule No.17 x月はあとy個使えます
      availableRequestCount: state => state.available_request_count,
      isSaturdayAvailable: state => state.is_saturday_available,
      role: state => state.role,
      sendAddrZipcode: state => state.send_addr_zipcode,
    }),
    currentMonth() {
      return (new Date()).getMonth() + 1
    },
    toPickupTime() {
      let now = new Date(new Date().valueOf())
      if (this.isStep2) {
        now = new Date(this.pickupDt.valueOf())
      } else {
        now.setHours(this.pickupHour)
        now.setMinutes(this.pickupMinute)
      }
      let toPickupTime = new Date(now.valueOf() + 60*90*1000)
      return toPickupTime
    }
  },
  async mounted() {
    // 休業日取得
    this.businessHolidays = await this.getBusinessHolidays()
    // 単発料金取得
    await userApi.getOnetimePayments().then(({ data }) => {
      this.onetimePayments = data
    })

    // 集配日時デフォルト値設定
    this.zipcode = this.sendAddrZipcode
    if (!this.zipcode) {
      await this.getMe()
    }
    const obj = {
      'zipcode': this.zipcode,
      'is_onetime': 1
    }
    areaInfoApi.getAreaInfo(obj).then(({ data }) => {
      this.areaInfo = data

      this.pickupConvHours = this.pickupConvHours.filter(v => v.val >= 13 && v.val <= 20)
      this.deliveryConvHours = this.deliveryConvHours.filter(v => v.val >= 16 && v.val <= 22)
      if (!data['error']) {
        this.lastPickupConvHours = this.pickupConvHours[this.pickupConvHours.length -1].val
        this.lastDeliveryConvHours = this.deliveryConvHours[this.deliveryConvHours.length -1].val
      }

      let pickupDt = new Date(new Date().valueOf())
      // 分に余りがあったら時間を進める
      let remainder = pickupDt.getMinutes() % 15
      if (remainder > 0) {
        pickupDt = new Date(pickupDt.valueOf() + 60*(15 - remainder)*1000);
      }

      // プラスした時間が当日中最終選択時間よりあとの場合はデフォルトの時間を翌日にするために更に時間をプラスする
      if (pickupDt.getHours() > this.lastPickupConvHours || (pickupDt.getHours() == this.lastPickupConvHours && pickupDt.getMinutes() > this.minutes30[this.minutes30.length -1].val)) {
        let to24 = 24 - pickupDt.getHours()
        pickupDt = new Date(pickupDt.valueOf() + 3600*to24*1000)
      }

      // 選べる時間が13時以降なので、それより前だったらそこまで進める
      const pickupHour = pickupDt.getHours()
      const pickupHourDiff = 13 - pickupHour
      if (pickupHourDiff > 0) {
        pickupDt.setHours(13)
        pickupDt.setMinutes(0)
      }

      pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)  // 休業日考慮
      this.pickupYear = pickupDt.getFullYear()
      this.pickupMonth = pickupDt.getMonth() + 1
      this.pickupDate = pickupDt.getDate()
      this.pickupHour = pickupDt.getHours()
      this.pickupMinute = pickupDt.getMinutes()
      if (this.lastPickupConvHours) {
        if ((pickupDt.getHours() > this.lastPickupConvHours)
        || (pickupDt.getHours() == this.lastPickupConvHours) && pickupDt.getMinutes() > this.minutes[this.minutes.length -2]) {
          this.pickupHour = this.lastPickupConvHours
          this.pickupMinute = 30
        }
      }

      let deliveryDt = new Date(pickupDt.valueOf() + 86400*1*1000)
      // 選べる時間が16時以降なので、それより前だったらそこまで進める
      const deliveryHour = pickupDt.getHours()
      const deliveryHourDiff = 16 - deliveryHour
      if (deliveryHourDiff > 0) {
        deliveryDt.setHours(16)
        deliveryDt.setMinutes(0)
      }

      deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays)  // 休業日考慮
      // 期間内で休日を除き24時間以上の時間があるか確認
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 2) {
        // 営業日数が２日未満の場合
        const addDate = 2 - workingDay
        deliveryDt.setDate((deliveryDt.getDate() + addDate))
      }
      this.deliveryYear = deliveryDt.getFullYear()
      this.deliveryMonth = deliveryDt.getMonth() + 1
      this.deliveryDate =  deliveryDt.getDate()
      this.deliveryHour =  deliveryDt.getHours()
      if (this.lastDeliveryConvHours) {
        if (deliveryDt.getHours() > this.lastDeliveryConvHours) {
          this.deliveryHour = this.lastDeliveryConvHours
          this.deliveryMinute = 0
        } else {
          this.deliveryMinute = deliveryDt.getMinutes()
        }
      }

      this.setPickupDates()
      this.setDeliveryDates()
      this.setPickupMinutes()
      this.setDeliveryMinutes()
      this.setPrice()
    })

    window.master.$promise.then(mst => {
      userApi.getMyCurrentPlan().then(({ data }) => {
        const planMst = mst.planMap[data.plan_id]
        Object.keys(planMst).forEach(k => {
          data[`mst_${k}`] = planMst[k]
        })
        // Business Rule No.16 ご利用プラン：契約中のプランが表示される
        this.currentPlan = data
      })
    })
  },
  mixins: [dateMixin],
  methods: {
    setMinuteOption(key) {
      if (!key) {
        return window.VueMinuteOptions
      }
      let filteredOptions = window.VueMinuteOptions.filter(option => key.includes(option.val));
      return filteredOptions
    },
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
        this.zipcode = me.send_addr_zipcode
      })
    },
    chageDate(wreqType, dateType) {
      if (wreqType === 1) {
        if (dateType === 1) {
          this.setPickupDates()
        } else if (dateType === 3) {
          this.setPickupMinutes()
        }
      } else if (wreqType === 2) {
        if (dateType === 1) {
          this.setDeliveryDates()
        } else if (dateType === 3) {
          this.setDeliveryMinutes()
        }
      }
      this.setPrice()
    },
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    setPickupDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      this.pickupDates = this.getWeekDaysList(this.pickupYear + yearAdjust, this.pickupMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.pickupDates.indexOf(this.pickupDate) === -1) {
        const pickupDt = new Date(
          this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDates[0],
          this.pickupHour, this.pickupMinute, 0)
        this.pickupDt = this.getAvailableDate(pickupDt, this.businessHolidays, this.isSaturdayAvailable)
        this.pickupDate = this.pickupDt.getDate()
      }
    },
    setDeliveryDates() {
      // 対象年月から休業日を除く日付を取得
      const yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      this.deliveryDates = this.getWeekDaysList(this.deliveryYear + yearAdjust, this.deliveryMonth, this.businessHolidays, this.isSaturdayAvailable)
      if (this.deliveryDates.indexOf(this.deliveryDate) === -1) {
        const deliveryDt = new Date(
          this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDates[0],
          this.deliveryHour, this.deliveryMinute, 0)
        this.deliveryDt = this.getAvailableDate(deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
        this.deliveryDate = this.deliveryDt.getDate()
      }
    },
    setPickupMinutes() {
      if (this.lastPickupConvHours === this.pickupHour) {
        // 20時を選択した場合、30分まで選択可能とする
        this.pickupMinute = this.setMinute(this.pickupMinute, 30)
        this.pickupMinutes = this.minutes30
      } else {
        this.pickupMinutes = this.minutes
      }
    },
    setDeliveryMinutes() {
      if (this.lastDeliveryConvHours === this.deliveryHour) {
        // 22時を選択した場合、00分まで選択可能とする
        this.deliveryMinute = this.setMinute(this.deliveryMinute, 0)
        this.deliveryMinutes = this.minutes0
      } else {
        this.deliveryMinutes = this.minutes
      }
    },
    setMinute(minute, to) {
      let minuteProp = 'minutes' + to
      let test = this[minuteProp][this[minuteProp].length - 1].val < minute ? to : minute
      return test
    },
    setPrice() {
      if (this.checkStep1(true)) {
        let week = this.pickupDt.getDay()
        let time = this.pickupDt.getHours() + ':00:00'
        let delivery_type = 1

        // 休業日を考慮
        let deliveryDtOnly = this.deliveryDt;
        const workingDay = this.getWorkingDay(this.pickupDt, this.deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
        if (workingDay < 3) {
          const diff = Math.round((this.deliveryDt - this.pickupDt) / (1000 * 60 * 60 * 24)) + 1;
          deliveryDtOnly = new Date(this.deliveryDt.valueOf() - 86400*diff*1000)
        }
        // 翌日 or 翌々日判定
        if (Math.floor((Math.abs(deliveryDtOnly - this.pickupDt)) / (1000 * 60 * 60 * 24)) > 1) {
          delivery_type = 2
        }
        let onetimePayment = this.onetimePayments.filter(v => v.wreq_time == time && v.delivery_type == delivery_type)
        this.price = this.count * onetimePayment[0]['week' + week]
        this.unitPrice = this.price / this.count
      } else {
        this.price = ''
        this.unitPrice =  ''
      }
    },
    checkStep1(isSetPrice = false) {
      this.resetLocalErrMsgs()
      this.resetServerErrMsgs()

      // 提供エリア外の場合は依頼不可
      if (this.areaInfo['error']) {
        if (!isSetPrice) this.showErrMsg4 = true
        return false
      }

      const now = new Date()
      let yearAdjust = this.isNextYear(this.pickupYear, this.pickupMonth) ? 1 : 0
      const pickupDt = new Date(
        this.pickupYear + yearAdjust, this.pickupMonth - 1, this.pickupDate,
        this.pickupHour, this.pickupMinute, 0)
      yearAdjust = this.isNextYear(this.deliveryYear, this.deliveryMonth) ? 1 : 0
      const deliveryDt = new Date(
        this.deliveryYear + yearAdjust, this.deliveryMonth - 1, this.deliveryDate,
        this.deliveryHour, this.deliveryMinute, 0)

      if (isNaN(pickupDt.valueOf()) || isNaN(deliveryDt.valueOf())) {
        if (!isSetPrice) this.showErrMsg1 = true
        return false
      }

      // 配達時間は最短でも集荷時間の24時間後以降しか選べない
      const minDiff = 86400*1*1000
      const result = deliveryDt.valueOf() - pickupDt.valueOf() >= minDiff
      if (!result) {
        if (!isSetPrice) this.showErrMsg2 = true
        return false
      }

      // かつ配達時間は休業日を除く24時間後しか依頼できない
      const workingDay = this.getWorkingDay(pickupDt, deliveryDt, this.businessHolidays, this.isSaturdayAvailable)
      if (workingDay < 2) {
        if (!isSetPrice) this.showErrMsg2 = true
        return false
      }

      // Business Rule No.4.1 配達時間は最長で30日後まで選択可能
      let deliveryMaxDt = new Date(now.valueOf() + 3600*24*30*1000)
      if (deliveryDt > deliveryMaxDt) {
        if (!isSetPrice) this.showErrMsg3 = true
        return false
      }

      // 選択された集荷日時が提供不可の場合は依頼不可
      if (this.price == 0) {
        if (!isSetPrice) {
          this.showErrMsg5 = true
          return false
        }
      }

      this.pickupDt = pickupDt
      this.deliveryDt = deliveryDt
      return true
    },
    checkStep2() {
      return true
    },
    addWashRequest() {
      if (!this.checkStep1()) { return }
      if (this.isWaitingResponse) { return }

      this.isWaitingResponse = true
      this.resetServerErrMsgs()

      const dtFormat = Vue.filter('dtFormat')
      const data = {
        sched_pickup_dt: dtFormat(
          this.toPickupTime, 'yyyy-mm-dd HH:MM:SS'),
        sched_delivery_dt: dtFormat(
          this.deliveryDt, 'yyyy-mm-dd HH:MM:SS'),
        count: this.count,
        delivery_box_pw: this.deliveryBoxPw,
        bikou: this.bikou,
        price: this.price
      }

      userApi.addOnetimeWashRequest(data).then(({ data }) => {
        this.isWaitingResponse = false
        this.updatePaymentFormValues(data.payment)
        setTimeout(() => {
          const form = document.querySelector('#payment-form')
          form.submit()
        }, 0)
      }).catch(err => {
        this.isWaitingResponse = false
        if (err.response.data.reason === 'dup') {
          this.isDupRequest = true
        }
        if (err.response.data.reason === 'status_disabled') {
          this.isStatusDisabled = true
        }
        if (err.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
        if (err.response.data.reason === 'status_stopped_other') {
          this.isStatusStoppedOther = true
        }
      })
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
    resetLocalErrMsgs() {
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      this.showErrMsg4 = false
      this.showErrMsg5 = false
    },
    resetServerErrMsgs() {
      this.isDupRequest = false
      this.isStatusDisabled = false
      this.isStatusWithdrawn = false
      this.isStatusStoppedOther = false
    }
  }
}
</script>

<style lang="scss" scoped>
.font_14 {
  font-size: 14px;
}
.plan_name_wrap {
  min-height: 30px;
  .plan_name {
    margin-right: 6px;
  }
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
.step1do:disabled {
  opacity: 0.6;
}

.btn_add_credit {
  margin: 0px 4px 0px 0px;
  padding: 4px 12px 4px 12px;
  background-color: #00a9ca;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    filter: alpha(opacity=75);
    -moz-opacity:0.75;
    opacity:0.75;
  }
}

select.count {
  width: 100px;
}
</style>
